body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Nunito',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  /*font-family: 'Nunito', sans-serif;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-table {
  /*border-radius: 1px !important;*/
}

.table-row-dark {
  background: #F2F3F5;
}

.ant-table-thead .ant-table-cell {
  background: #8993A9 !important;
  height: 36px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ant-table-cell {
  height: 36px;
  padding: 0px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ant-select {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
}

.ant-input {
  width: 300px;
  /*min-width: 300px;*/
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-input-password {
  .ant-input {
    width: 100%;
  }
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.company-disabled {
  background: #dddddd;
  opacity: 0.7;
}

pre {
  background-color: #f5f5f5;
  padding: 12px;
  border: 1px solid #cfcfcf;
  border-radius: 6px;
  overflow: auto;
}
